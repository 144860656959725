import React, { forwardRef } from 'react'
import { Flex, FlexProps } from 'rebass'

type Props = FlexProps

const SFlex = forwardRef<any, Props>((props: Props, ref) => {
    return <Flex ref={ref} {...props} />
})

SFlex.displayName = 'SFlex'

export default SFlex
