import React, { forwardRef } from 'react'
import { Text, TextProps } from 'rebass'
import Truncate from 'react-truncate'

interface Props extends TextProps {
    maxLines?: number
}

const SText = forwardRef<any, Props>((props: Props, ref) => {
    const { maxLines, children, ...otherProps } = props

    const content = maxLines ? (
        <Truncate lines={maxLines} trimWhitespace={true}>
            {children}
        </Truncate>
    ) : (
        children
    )

    return (
        <Text ref={ref} {...otherProps}>
            {content}
        </Text>
    )
})

SText.displayName = 'SText'

export default SText
