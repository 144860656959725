import * as React from 'react'
import { gql, useApolloClient } from '@apollo/client'
import moment from 'moment'
// import { MeQueryResult, useMeLazyQuery } from 'types/graphql'
import { LocalStorageKeys } from './localStorage'
import { useRouter } from 'next/router'

export enum LogoutReason {
    General = 'general',
    Inactivity = 'inactivity',
}

interface AuthContextType {
    user: null
    loading: boolean
    refreshAuthState: () => void
    logout: (reason?: LogoutReason) => void
    error: any
}

const InitialValue = {
    user: null,
    loading: true,
    refreshAuthState: null,
    logout: null,
    error: undefined,
}

const AuthContext = React.createContext<AuthContextType>(InitialValue)

interface Props {
    children: React.ReactNode
}

// export const TRANSFER_METHODS_FRAGMENT = gql`
//     fragment TransferMethods on UserType {
//         transferMethods {
//             edges {
//                 node {
//                     id
//                     type
//                     halfLiner
//                     oneLiner
//                     isCurrentMethod
//                     address1
//                     address2
//                     city
//                     zipCode
//                     statusCode
//                 }
//             }
//         }
//     }
// `

// export const LOGOUT = gql`
//     mutation Logout { 
//         logout {
//             ok
//         }
//     }
// `

// export const MAKEPAYMENTS = gql`
//     mutation MakePayments { 
//         makepayments {
//             ok
//         }
//     }
// `

// const GET_ME = gql`
//     ${TRANSFER_METHODS_FRAGMENT}
//     query Me {
//         me {
//             id
//             firstName
//             lastName
//             usioToken
//             hyperwalletToken
//             phoneNumber
//             email
//             createdAt
//             pk
//             isSuperuser
//             paymentAmount
//             paymentFrequencyType
//             ...TransferMethods
//         }
//     }
// `

const AuthContextProvider: React.FC<Props> = (props: Props) => {
    const apolloClient = useApolloClient()

    const router = useRouter()

    const { children } = props
    // const [fetchMe, { data, loading, error, called }] = useMeLazyQuery({
    //     ssr: false,
    //     fetchPolicy: 'cache-and-network',
    // })

    React.useEffect(() => {
        if (localStorage.getItem(LocalStorageKeys.TOKEN_KEY)) {
            // fetchMe()
        } else {
            window.Intercom('boot', {
                app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
            })
        }
    }, [])

    const logout = () => {}
    // React.useCallback(
    //     (reason?: LogoutReason) => {
    //         localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN_KEY)
    //         localStorage.removeItem(LocalStorageKeys.TOKEN_KEY)

    //         apolloClient.clearStore()
    //         window.Intercom('shutdown')
    //         window.Intercom('boot', {
    //             app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    //         })

    //         const url = reason === LogoutReason.Inactivity ? '/login?inactivity=1' : '/login'
    //         window.location.href = url
    //     },
    //     [data, apolloClient, fetchMe]
    // )

    const refreshAuthState = () => {}
    
    // React.useCallback(() => {
    //     fetchMe()
    // }, [fetchMe])

    React.useEffect(() => {
        if (true) {
            let bootParams: {
                [key: string]: any
            } = {
                app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
            }
            // if (data && data.me) {
            //     const createdAtMoment = moment(data.me.createdAt)
            //     bootParams = {
            //         ...bootParams,
            //         email: data.me.email,
            //         user_id: `${process.env.NEXT_PUBLIC_ENVIRONMENT_NAME}-${data.me.pk}`,
            //         name: `${data.me.firstName} ${data.me.lastName}`,
            //         created_at: createdAtMoment.utc(),
            //     }
            // }

            window.Intercom('boot', bootParams)
        }
    }, [])

    return (
        <AuthContext.Provider
            value={{
                loading: false,
                user: null,
                error: null,
                refreshAuthState,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthContextProvider }
