import NextI18Next from 'next-i18next'
import getConfig from 'next/config'
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
import path from 'path'

const { localeSubpaths } = publicRuntimeConfig

const localePath = path.resolve('./public/locales')
const S18N = new NextI18Next({
    defaultLanguage: 'en',
    otherLanguages: ['en', 'es'],
    localeSubpaths: localeSubpaths as Record<string, string>,
    localePath: localePath,
    debug: process.env.NODE_ENV === 'development',
})

export const appWithTranslation = S18N.appWithTranslation
export const useTranslation = S18N.useTranslation
export const Trans = S18N.Trans
export const s18n = S18N.i18n
